import {createSlice} from '@reduxjs/toolkit';

const initialState={
    showSideBar:false
}

const UISlice=createSlice({
    name:'ui',
    initialState,
    reducers:{
        toggleSideBar(state){
            state.showSideBar=!state.showSideBar
        }
    }
})

export const {toggleSideBar}=UISlice.actions;
export default UISlice.reducer;