import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userObject: null,
  isLoggedIn: false,
  userPermissions: [],
  rememberMe:false,
  rememberMeData:""
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state, action) {
      const {
        payload: { userObject, userPermissions },
      } = action;
      state.userObject = userObject;
      state.userPermissions = userPermissions;
      state.isLoggedIn = true;
    },
    logout(state) {
      state.userObject = null;
      state.isLoggedIn = false;
      state.userPermissions = [];
    },
    updateUser(state,action){
      state.userObject=action.payload;
    },
    addRememberMe(state,{payload}){
      
      state.rememberMeData=payload
    },
    removeRememberMe(state){
     
      state.rememberMeData=""
    }

  },
});

export const { login, logout,addRememberMe,removeRememberMe,updateUser } = authSlice.actions;

export default authSlice.reducer;
