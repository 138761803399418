// Sidebar icons
import {
    DarkDashboardIcon,
    LightDashboardIcon,
    DarkIncidentsIcon,
    LightIncidentsIcon,
    DarkRegistriesIcon,
    LightRegistriesIcon,
    DarkAnalysisIcon,
    LightAnalysisIcon,
    DarkManagementIcon,
    LightManagementIcon,
    LightOrgIcon,
    DarkOrgIcon,
    DarkReportIcon,
    LightReportIcon
  } from "../../assets/images/index";

const sidebarIcons={
    DarkDashboardIcon,
    LightDashboardIcon,
    DarkIncidentsIcon,
    LightIncidentsIcon,
    DarkRegistriesIcon,
    LightRegistriesIcon,
    DarkAnalysisIcon,
    LightAnalysisIcon,
    DarkManagementIcon,
    LightManagementIcon,
    LightOrgIcon,
    DarkOrgIcon,
    DarkReportIcon,
    LightReportIcon
}
export default sidebarIcons
 
